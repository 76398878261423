export const DISMISS_ERROR = 'DISMISS_ERROR'

/**
 * Multiple Word Actions
 */
export const FV_WORDS_SHARED_FETCH_START = 'FV_WORDS_SHARED_FETCH_START'
export const FV_WORDS_SHARED_FETCH_SUCCESS = 'FV_WORDS_SHARED_FETCH_SUCCESS'
export const FV_WORDS_SHARED_FETCH_ERROR = 'FV_WORDS_SHARED_FETCH_ERROR'

/**
 * Single Word Actions
 */
export const FV_WORD_FETCH_ALL_START = 'FV_WORD_FETCH_ALL_START'
export const FV_WORD_FETCH_ALL_SUCCESS = 'FV_WORD_FETCH_ALL_SUCCESS'
export const FV_WORD_FETCH_ALL_ERROR = 'FV_WORD_FETCH_ALL_ERROR'

export const FV_WORDS_USER_MODIFIED_QUERY_START = 'FV_WORDS_USER_MODIFIED_QUERY_START'
export const FV_WORDS_USER_MODIFIED_QUERY_SUCCESS = 'FV_WORDS_USER_MODIFIED_QUERY_SUCCESS'
export const FV_WORDS_USER_MODIFIED_QUERY_ERROR = 'FV_WORDS_USER_MODIFIED_QUERY_ERROR'

export const FV_WORDS_USER_CREATED_QUERY_START = 'FV_WORDS_USER_CREATED_QUERY_START'
export const FV_WORDS_USER_CREATED_QUERY_SUCCESS = 'FV_WORDS_USER_CREATED_QUERY_SUCCESS'
export const FV_WORDS_USER_CREATED_QUERY_ERROR = 'FV_WORDS_USER_CREATED_QUERY_ERROR'

/*
import {
    DISMISS_ERROR,
    FV_WORDS_SHARED_FETCH_START,
    FV_WORDS_SHARED_FETCH_SUCCESS,
    FV_WORDS_SHARED_FETCH_ERROR,
    FV_WORD_FETCH_ALL_START,
    FV_WORD_FETCH_ALL_SUCCESS,
    FV_WORD_FETCH_ALL_ERROR,
    FV_WORDS_USER_MODIFIED_QUERY_START,
    FV_WORDS_USER_MODIFIED_QUERY_SUCCESS,
    FV_WORDS_USER_MODIFIED_QUERY_ERROR,
    FV_WORDS_USER_CREATED_QUERY_START,
    FV_WORDS_USER_CREATED_QUERY_SUCCESS,
    FV_WORDS_USER_CREATED_QUERY_ERROR,
} from './actionTypes'
*/
