/*
Copyright 2016 First People's Cultural Council

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import FVButton from 'components/FVButton'

// REDUX
import { connect } from 'react-redux'
// REDUX: actions/dispatch/func
import { pushWindowPath, replaceWindowPath } from 'reducers/windowPath'
import { fetchSourceDocument, fetchResultSet } from 'reducers/document'

import StringHelpers from 'common/StringHelpers'

import './WorkspaceSwitcher.css'

const { array, func, object, string } = PropTypes

export class WorkspaceSwitcher extends Component {
  static propTypes = {
    area: string,
    className: string,
    // REDUX: reducers/state
    routeParams: object.isRequired,
    computeSourceDocument: object.isRequired,
    computeResultSet: object.isRequired,
    splitWindowPath: array.isRequired,
    windowPath: string.isRequired,
    // REDUX: actions/dispatch/func
    pushWindowPath: func.isRequired,
    replaceWindowPath: func.isRequired,
    fetchSourceDocument: func.isRequired,
    fetchResultSet: func.isRequired,
  }

  static defaultProps = {
    className: '',
  }

  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false
    }
  }

  _getPotentialUUID = () => {
    return this.props.splitWindowPath[this.props.splitWindowPath.length - 1]
  }

  _getSourceDocument = async () => {
    const potentialUUID = this._getPotentialUUID()
    if (StringHelpers.isUUID(potentialUUID)) {
      await this.props.fetchSourceDocument(potentialUUID)
    } else {
      window.location.href = this._toggleWorkspaceSections(this.props.windowPath)
    }
  }

  render() {
    return (
      <React.Fragment>
      <ul
        className={`WorkspaceSwitcher ${this.props.className} WorkspaceSwitcher--workspace`}
      >
        <li
          role="presentation"
          className={`WorkspaceSwitcher__itemContainer WorkspaceSwitcher__itemContainer--active`}
        >
          <span className="WorkspaceSwitcher__item">Workspace</span>
        </li>
        <li className={`WorkspaceSwitcher__itemContainer`}>
          <button
            className="WorkspaceSwitcher__item"
            onClick={(e) => {
              e.preventDefault()
              this.setState({ modalOpen: true })
            }}
          >
            Public View
          </button>
        </li>
      </ul>
            <Dialog
            fullWidth
            maxWidth="sm"
            open={this.state.modalOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogTitle>
                We're simplifying the way users interact with Workspaces and Public content.
              </DialogTitle>
              <DialogContentText>
                <p style={{margin: '0 25px'}}>
                  As a logged in user, your default view is 'Workspaces'. In this view, you may <strong>see content that is available to you due to your group or permissions level</strong>, based on the level assigned by Language Admins.
                </p>
                <p style={{margin: '10px 25px'}}>
                  To learn more about how this works, please visit our <a href="https://firstvoices.atlassian.net/wiki/spaces/FIR1/pages/1705431/Permissions+workspaces+and+public+views" target="_blank">Knowledge Base article about permissions, workspaces, and public views</a>.
                </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <FVButton onClick={(e) => {
                  e.preventDefault()
                  this.setState({ modalOpen: false })
                }} variant="contained" color="primary" autoFocus>
                OK
              </FVButton>
            </DialogActions>
          </Dialog>
        </React.Fragment>
    )
  }
}

// REDUX: reducers/state
const mapStateToProps = (state /*, ownProps*/) => {
  const { document, navigation, windowPath } = state

  const { computeResultSet, computeSourceDocument } = document
  const { splitWindowPath, _windowPath } = windowPath
  const { route } = navigation
  return {
    computeResultSet,
    computeSourceDocument,
    routeParams: route.routeParams,
    splitWindowPath,
    windowPath: _windowPath,
  }
}

// REDUX: actions/dispatch/func
const mapDispatchToProps = {
  pushWindowPath,
  replaceWindowPath,
  fetchSourceDocument,
  fetchResultSet,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSwitcher)
