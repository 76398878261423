import { create, _delete, execute, fetch, query } from 'reducers/rest'
import DirectoryOperations from 'operations/DirectoryOperations'
import {
  FV_PHRASES_SHARED_FETCH_START,
  FV_PHRASES_SHARED_FETCH_SUCCESS,
  FV_PHRASES_SHARED_FETCH_ERROR,
  FV_PHRASE_FETCH_ALL_START,
  FV_PHRASE_FETCH_ALL_SUCCESS,
  FV_PHRASE_FETCH_ALL_ERROR,
  FV_PHRASES_USER_MODIFIED_QUERY_START,
  FV_PHRASES_USER_MODIFIED_QUERY_SUCCESS,
  FV_PHRASES_USER_MODIFIED_QUERY_ERROR,
  FV_PHRASES_USER_CREATED_QUERY_START,
  FV_PHRASES_USER_CREATED_QUERY_SUCCESS,
  FV_PHRASES_USER_CREATED_QUERY_ERROR,
} from './actionTypes'

import { ENRICHER_HEADER } from 'common/Constants'

export const fetchSharedPhrases = function fetchSharedPhrases(pageProvider, headers = {}, params = {}) {
  return (dispatch) => {
    dispatch({ type: FV_PHRASES_SHARED_FETCH_START })

    return DirectoryOperations.getDocumentsViaPageProvider(pageProvider, 'FVPhrase', headers, params)
      .then((response) => {
        dispatch({ type: FV_PHRASES_SHARED_FETCH_SUCCESS, documents: response })
      })
      .catch((error) => {
        dispatch({ type: FV_PHRASES_SHARED_FETCH_ERROR, error: error })
      })
  }
}

export const fetchPhrasesAll = function fetchPhrasesAll(path /*, type*/) {
  return (dispatch) => {
    dispatch({ type: FV_PHRASE_FETCH_ALL_START })

    return DirectoryOperations.getDocuments(path, 'FVPhrase', '', {
      headers: { [ENRICHER_HEADER]: 'ancestry,phrase,permissions' },
    })
      .then((response) => {
        dispatch({ type: FV_PHRASE_FETCH_ALL_SUCCESS, documents: response })
      })
      .catch((error) => {
        dispatch({ type: FV_PHRASE_FETCH_ALL_ERROR, error: error })
      })
  }
}

export const fetchPhrase = fetch('FV_PHRASE', 'FVPhrase', {
  headers: { [ENRICHER_HEADER]: 'ancestry,phrase,permissions,draft' },
})

export const fetchPhrases = query('FV_PHRASES', 'FVPhrase', { headers: { [ENRICHER_HEADER]: 'phrase,draft' } })

export const fetchDrafts = query('FV_PHRASES_DRAFTS', 'FVPhrase', {
  headers: {
    [ENRICHER_HEADER]: 'phrase,draft',
    properties: 'dublincore, fv-phrase, fvcore, fvproxy',
  },
})

export const createPhrase = create('FV_PHRASE', 'FVPhrase', {})

export const createAndPublishPhrase = create('FV_PHRASE', 'FVPhrase', {
  headers: { 'fv-publish': true },
})

export const deletePhrase = _delete('FV_PHRASE', 'FVPhrase', {})

export const createDraft = execute('FV_CREATE_DRAFT', 'Document.CreateDraftForRecorder', {
  headers: { [ENRICHER_HEADER]: 'ancestry,phrase,permissions,draft' },
})

export const queryModifiedPhrases = query('FV_MODIFIED_PHRASES', 'FVPhrase', {
  queryAppend: '&sortBy=dc:modified&sortOrder=DESC&pageSize=4',
  headers: { properties: 'dublincore' },
})

export const queryCreatedPhrases = query('FV_CREATED_PHRASES', 'FVPhrase', {
  queryAppend: '&sortBy=dc:created&sortOrder=DESC&pageSize=4',
  headers: { properties: 'dublincore' },
})

export const queryUserModifiedPhrases = function queryUserModifiedPhrases(pathOrId, user) {
  return (dispatch) => {
    dispatch({ type: FV_PHRASES_USER_MODIFIED_QUERY_START })

    return DirectoryOperations.getDocuments(
      pathOrId,
      'FVPhrase',
      " AND dc:lastContributor='" + user + "'&sortBy=dc:modified&sortOrder=DESC&pageSize=4",
      { properties: 'dublincore' }
    )
      .then((response) => {
        dispatch({ type: FV_PHRASES_USER_MODIFIED_QUERY_SUCCESS, document: response })
      })
      .catch((error) => {
        dispatch({ type: FV_PHRASES_USER_MODIFIED_QUERY_ERROR, error: error })
      })
  }
}

export const queryUserCreatedPhrases = function queryUserCreatedPhrases(pathOrId, user) {
  return (dispatch) => {
    dispatch({ type: FV_PHRASES_USER_CREATED_QUERY_START })

    return DirectoryOperations.getDocuments(
      pathOrId,
      'FVPhrase',
      " AND dc:lastContributor='" + user + "'&sortBy=dc:created&sortOrder=DESC&pageSize=4",
      { properties: 'dublincore' }
    )
      .then((response) => {
        dispatch({ type: FV_PHRASES_USER_CREATED_QUERY_SUCCESS, document: response })
      })
      .catch((error) => {
        dispatch({ type: FV_PHRASES_USER_CREATED_QUERY_ERROR, error: error })
      })
  }
}
