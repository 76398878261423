/* NOTE: Some actions use dynamic actionTypes and so aren't listed here
eg:
```
dispatch({
    type: key + '_FETCH_START',
```
*/

export const FV_JOIN_REQUEST_FETCH_START = 'FV_JOIN_REQUEST_FETCH_START'
export const FV_JOIN_REQUEST_FETCH_SUCCESS = 'FV_JOIN_REQUEST_FETCH_SUCCESS'
export const FV_JOIN_REQUEST_FETCH_ERROR = 'FV_JOIN_REQUEST_FETCH_ERROR'
export const FV_JOIN_REQUESTS_FETCH_START = 'FV_JOIN_REQUESTS_FETCH_START'
export const FV_JOIN_REQUESTS_FETCH_SUCCESS = 'FV_JOIN_REQUESTS_FETCH_SUCCESS'
export const FV_JOIN_REQUESTS_FETCH_ERROR = 'FV_JOIN_REQUESTS_FETCH_ERROR'
export const FV_JOIN_REQUEST_UPDATE_START = 'FV_JOIN_REQUEST_UPDATE_START'
export const FV_JOIN_REQUEST_UPDATE_SUCCESS = 'FV_JOIN_REQUEST_UPDATE_SUCCESS'
export const FV_JOIN_REQUEST_UPDATE_ERROR = 'FV_JOIN_REQUEST_UPDATE_ERROR'
export const FV_MEMBERSHIP_FETCH_START = 'FV_MEMBERSHIP_FETCH_START'
export const FV_MEMBERSHIP_FETCH_SUCCESS = 'FV_MEMBERSHIP_FETCH_SUCCESS'
export const FV_MEMBERSHIP_FETCH_ERROR = 'FV_MEMBERSHIP_FETCH_ERROR'
export const FV_MEMBERSHIP_CREATE_START = 'FV_MEMBERSHIP_CREATE_START'
export const FV_MEMBERSHIP_CREATE_SUCCESS = 'FV_MEMBERSHIP_CREATE_SUCCESS'
export const FV_MEMBERSHIP_CREATE_ERROR = 'FV_MEMBERSHIP_CREATE_ERROR'
