// Action export constants
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const NAVIGATE_PAGE = 'NAVIGATE_PAGE'
export const CHANGE_SITE_THEME = 'CHANGE_SITE_THEME'
export const CHANGE_TITLE_PARAMS = 'CHANGE_TITLE_PARAMS'
export const OVERRIDE_BREADCRUMBS = 'OVERRIDE_BREADCRUMBS'
export const PAGE_PROPERTIES = 'PAGE_PROPERTIES'

export const LOAD_NAVIGATION_STARTED = 'LOAD_NAVIGATION_STARTED'
export const LOAD_NAVIGATION_SUCCESS = 'LOAD_NAVIGATION_SUCCESS'
export const LOAD_NAVIGATION_ERROR = 'LOAD_NAVIGATION_ERROR'

export const SET_ROUTE_PARAMS = 'navigation/SET_ROUTE_PARAMS'