import { fetch, query } from 'reducers/rest'
import { ENRICHER_HEADER } from 'common/Constants'

export const fetchCharacter = fetch('FV_CHARACTER', 'FVCharacter', {
  headers: { [ENRICHER_HEADER]: 'ancestry,character,permissions' },
})

export const fetchCharacters = query('FV_CHARACTERS', 'FVCharacter', {
  headers: { [ENRICHER_HEADER]: 'character' },
})

