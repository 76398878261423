export const FV_SIMPLE_TASKS_GET_START = 'FV_SIMPLE_TASKS_GET_START'
export const FV_SIMPLE_TASKS_GET_SUCCESS = 'FV_SIMPLE_TASKS_GET_SUCCESS'
export const FV_SIMPLE_TASKS_GET_ERROR = 'FV_SIMPLE_TASKS_GET_ERROR'
export const FV_SIMPLE_TASK_GET_START = 'FV_SIMPLE_TASK_GET_START'
export const FV_SIMPLE_TASK_GET_SUCCESS = 'FV_SIMPLE_TASK_GET_SUCCESS'
export const FV_SIMPLE_TASK_GET_ERROR = 'FV_SIMPLE_TASK_GET_ERROR'
export const FV_REQUEST_REVIEW_POST_START = 'FV_REQUEST_REVIEW_POST_START'
export const FV_REQUEST_REVIEW_POST_SUCCESS = 'FV_REQUEST_REVIEW_POST_SUCCESS'
export const FV_REQUEST_REVIEW_POST_ERROR = 'FV_REQUEST_REVIEW_POST_ERROR'
export const FV_CREATE_DRAFT_POST_START = 'FV_CREATE_DRAFT_POST_START'
export const FV_CREATE_DRAFT_POST_SUCCESS = 'FV_CREATE_DRAFT_POST_SUCCESS'
export const FV_CREATE_DRAFT_POST_ERROR = 'FV_CREATE_DRAFT_POST_ERROR'
export const FV_APPLY_DRAFT_POST_START = 'FV_APPLY_DRAFT_POST_START'
export const FV_APPLY_DRAFT_POST_SUCCESS = 'FV_APPLY_DRAFT_POST_SUCCESS'
export const FV_APPLY_DRAFT_POST_ERROR = 'FV_APPLY_DRAFT_POST_ERROR'
export const FV_PROCESSED_TASK = 'FV_PROCESSED_TASK'
export const FV_SIMPLE_TASKS_APPROVE_PUT_START = 'FV_SIMPLE_TASKS_APPROVE_PUT_START'
export const FV_SIMPLE_TASKS_APPROVE_PUT_SUCCESS = 'FV_SIMPLE_TASKS_APPROVE_PUT_SUCCESS'
export const FV_SIMPLE_TASKS_APPROVE_PUT_ERROR = 'FV_SIMPLE_TASKS_APPROVE_PUT_ERROR'
export const FV_SIMPLE_TASKS_REQUEST_CHANGES_START = 'FV_SIMPLE_TASKS_REQUEST_CHANGES_START'
export const FV_SIMPLE_TASKS_REQUEST_CHANGES_SUCCESS = 'FV_SIMPLE_TASKS_REQUEST_CHANGES_SUCCESS'
export const FV_SIMPLE_TASKS_REQUEST_CHANGES_ERROR = 'FV_SIMPLE_TASKS_REQUEST_CHANGES_ERROR'
export const FV_SIMPLE_TASKS_IGNORE_START = 'FV_SIMPLE_TASKS_IGNORE_START'
export const FV_SIMPLE_TASKS_IGNORE_SUCCESS = 'FV_SIMPLE_TASKS_IGNORE_SUCCESS'
export const FV_SIMPLE_TASKS_IGNORE_ERROR = 'FV_SIMPLE_TASKS_IGNORE_ERROR'
