export const DISMISS_ERROR = 'DISMISS_ERROR'

/**
 * Multiple Category Actions
 */
export const FV_CATEGORIES_FETCH_START = 'FV_CATEGORIES_FETCH_START'
export const FV_CATEGORIES_FETCH_SUCCESS = 'FV_CATEGORIES_FETCH_SUCCESS'
export const FV_CATEGORIES_FETCH_ERROR = 'FV_CATEGORIES_FETCH_ERROR'

export const FV_CATEGORIES_UPDATE_START = 'FV_CATEGORIES_UPDATE_START'
export const FV_CATEGORIES_UPDATE_SUCCESS = 'FV_CATEGORIES_UPDATE_SUCCESS'
export const FV_CATEGORIES_UPDATE_ERROR = 'FV_CATEGORIES_UPDATE_ERROR'

export const FV_CATEGORIES_CREATE_START = 'FV_CATEGORIES_CREATE_START'
export const FV_CATEGORIES_CREATE_SUCCESS = 'FV_CATEGORIES_CREATE_SUCCESS'
export const FV_CATEGORIES_CREATE_ERROR = 'FV_CATEGORIES_CREATE_ERROR'

export const FV_CATEGORIES_DELETE_START = 'FV_CATEGORIES_DELETE_START'
export const FV_CATEGORIES_DELETE_SUCCESS = 'FV_CATEGORIES_DELETE_SUCCESS'
export const FV_CATEGORIES_DELETE_ERROR = 'FV_CATEGORIES_DELETE_ERROR'

/**
 * Single Category Actions
 */
export const FV_CATEGORY_FETCH_START = 'FV_CATEGORY_FETCH_START'
export const FV_CATEGORY_FETCH_SUCCESS = 'FV_CATEGORY_FETCH_SUCCESS'
export const FV_CATEGORY_FETCH_ERROR = 'FV_CATEGORY_FETCH_ERROR'

export const FV_CATEGORY_FETCH_ALL_START = 'FV_CATEGORY_FETCH_ALL_START'
export const FV_CATEGORY_FETCH_ALL_SUCCESS = 'FV_CATEGORY_FETCH_ALL_SUCCESS'
export const FV_CATEGORY_FETCH_ALL_ERROR = 'FV_CATEGORY_FETCH_ALL_ERROR'

export const FV_CATEGORY_UPDATE_START = 'FV_CATEGORY_UPDATE_START'
export const FV_CATEGORY_UPDATE_SUCCESS = 'FV_CATEGORY_UPDATE_SUCCESS'
export const FV_CATEGORY_UPDATE_ERROR = 'FV_CATEGORY_UPDATE_ERROR'

export const FV_CATEGORY_CREATE_START = 'FV_CATEGORY_CREATE_START'
export const FV_CATEGORY_CREATE_SUCCESS = 'FV_CATEGORY_CREATE_SUCCESS'
export const FV_CATEGORY_CREATE_ERROR = 'FV_CATEGORY_CREATE_ERROR'

export const FV_CATEGORY_DELETE_START = 'FV_CATEGORY_DELETE_START'
export const FV_CATEGORY_DELETE_SUCCESS = 'FV_CATEGORY_DELETE_SUCCESS'
export const FV_CATEGORY_DELETE_ERROR = 'FV_CATEGORY_DELETE_ERROR'

/*
import {
    DISMISS_ERROR,
    FV_CATEGORIES_FETCH_START,
    FV_CATEGORIES_FETCH_SUCCESS,
    FV_CATEGORIES_FETCH_ERROR,
    FV_CATEGORIES_UPDATE_START,
    FV_CATEGORIES_UPDATE_SUCCESS,
    FV_CATEGORIES_UPDATE_ERROR,
    FV_CATEGORIES_CREATE_START,
    FV_CATEGORIES_CREATE_SUCCESS,
    FV_CATEGORIES_CREATE_ERROR,
    FV_CATEGORIES_DELETE_START,
    FV_CATEGORIES_DELETE_SUCCESS,
    FV_CATEGORIES_DELETE_ERROR,
    FV_CATEGORY_FETCH_START,
    FV_CATEGORY_FETCH_SUCCESS,
    FV_CATEGORY_FETCH_ERROR,
    FV_CATEGORY_FETCH_ALL_START,
    FV_CATEGORY_FETCH_ALL_SUCCESS,
    FV_CATEGORY_FETCH_ALL_ERROR,
    FV_CATEGORY_UPDATE_START,
    FV_CATEGORY_UPDATE_SUCCESS,
    FV_CATEGORY_UPDATE_ERROR,
    FV_CATEGORY_CREATE_START,
    FV_CATEGORY_CREATE_SUCCESS,
    FV_CATEGORY_CREATE_ERROR,
    FV_CATEGORY_DELETE_START,
    FV_CATEGORY_DELETE_SUCCESS,
    FV_CATEGORY_DELETE_ERROR,
} from './actionTypes'
*/
