export const DISMISS_ERROR = 'DISMISS_ERROR'

/**
 * Multiple Audio Actions
 */
export const FV_AUDIOS_FETCH_START = 'FV_AUDIOS_FETCH_START'
export const FV_AUDIOS_FETCH_SUCCESS = 'FV_AUDIOS_FETCH_SUCCESS'
export const FV_AUDIOS_FETCH_ERROR = 'FV_AUDIOS_FETCH_ERROR'

export const FV_AUDIOS_UPDATE_START = 'FV_AUDIOS_UPDATE_START'
export const FV_AUDIOS_UPDATE_SUCCESS = 'FV_AUDIOS_UPDATE_SUCCESS'
export const FV_AUDIOS_UPDATE_ERROR = 'FV_AUDIOS_UPDATE_ERROR'

export const FV_AUDIOS_CREATE_START = 'FV_AUDIOS_CREATE_START'
export const FV_AUDIOS_CREATE_SUCCESS = 'FV_AUDIOS_CREATE_SUCCESS'
export const FV_AUDIOS_CREATE_ERROR = 'FV_AUDIOS_CREATE_ERROR'

export const FV_AUDIOS_DELETE_START = 'FV_AUDIOS_DELETE_START'
export const FV_AUDIOS_DELETE_SUCCESS = 'FV_AUDIOS_DELETE_SUCCESS'
export const FV_AUDIOS_DELETE_ERROR = 'FV_AUDIOS_DELETE_ERROR'

export const FV_AUDIOS_SHARED_FETCH_START = 'FV_AUDIOS_SHARED_FETCH_START'
export const FV_AUDIOS_SHARED_FETCH_SUCCESS = 'FV_AUDIOS_SHARED_FETCH_SUCCESS'
export const FV_AUDIOS_SHARED_FETCH_ERROR = 'FV_AUDIOS_SHARED_FETCH_ERROR'

/**
 * Single Audio Actions
 */
export const FV_AUDIO_FETCH_START = 'FV_AUDIO_FETCH_START'
export const FV_AUDIO_FETCH_SUCCESS = 'FV_AUDIO_FETCH_SUCCESS'
export const FV_AUDIO_FETCH_ERROR = 'FV_AUDIO_FETCH_ERROR'

export const FV_AUDIO_UPLOAD_START = 'FV_AUDIO_UPLOAD_START'
export const FV_AUDIO_UPLOAD_SUCCESS = 'FV_AUDIO_UPLOAD_SUCCESS'
export const FV_AUDIO_UPLOAD_ERROR = 'FV_AUDIO_UPLOAD_ERROR'

export const FV_AUDIO_UPDATE_START = 'FV_AUDIO_UPDATE_START'
export const FV_AUDIO_UPDATE_SUCCESS = 'FV_AUDIO_UPDATE_SUCCESS'
export const FV_AUDIO_UPDATE_ERROR = 'FV_AUDIO_UPDATE_ERROR'

export const FV_AUDIO_CREATE_START = 'FV_AUDIO_CREATE_START'
export const FV_AUDIO_CREATE_SUCCESS = 'FV_AUDIO_CREATE_SUCCESS'
export const FV_AUDIO_CREATE_ERROR = 'FV_AUDIO_CREATE_ERROR'

export const FV_AUDIO_DELETE_START = 'FV_AUDIO_DELETE_START'
export const FV_AUDIO_DELETE_SUCCESS = 'FV_AUDIO_DELETE_SUCCESS'
export const FV_AUDIO_DELETE_ERROR = 'FV_AUDIO_DELETE_ERROR'

/*
import {
DISMISS_ERROR,
FV_AUDIOS_FETCH_START,
FV_AUDIOS_FETCH_SUCCESS,
FV_AUDIOS_FETCH_ERROR,
FV_AUDIOS_UPDATE_START,
FV_AUDIOS_UPDATE_SUCCESS,
FV_AUDIOS_UPDATE_ERROR,
FV_AUDIOS_CREATE_START,
FV_AUDIOS_CREATE_SUCCESS,
FV_AUDIOS_CREATE_ERROR,
FV_AUDIOS_DELETE_START,
FV_AUDIOS_DELETE_SUCCESS,
FV_AUDIOS_DELETE_ERROR,
FV_AUDIOS_SHARED_FETCH_START,
FV_AUDIOS_SHARED_FETCH_SUCCESS,
FV_AUDIOS_SHARED_FETCH_ERROR,
FV_AUDIO_FETCH_START,
FV_AUDIO_FETCH_SUCCESS,
FV_AUDIO_FETCH_ERROR,
FV_AUDIO_UPLOAD_START,
FV_AUDIO_UPLOAD_SUCCESS,
FV_AUDIO_UPLOAD_ERROR,
FV_AUDIO_UPDATE_START,
FV_AUDIO_UPDATE_SUCCESS,
FV_AUDIO_UPDATE_ERROR,
FV_AUDIO_CREATE_START,
FV_AUDIO_CREATE_SUCCESS,
FV_AUDIO_CREATE_ERROR,
FV_AUDIO_DELETE_START,
FV_AUDIO_DELETE_SUCCESS,
FV_AUDIO_DELETE_ERROR,
} from './actionTypes'
*/
