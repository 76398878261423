export default {
  fontFamily: 'Arial, sans-serif',
  fontSize: 18,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  htmlFontSize: 16,
  fontWeightBold: 700,
  h1: {
    // color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: 'Arial, sans-serif',
    fontSize: '7.2rem',
    fontWeight: 300,
    letterSpacing: '-.04em',
    lineHeight: '1.14286em',
    // marginLeft: '-.04em',
  },
  h2: {
    // color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: 'Arial, sans-serif',
    fontSize: '6rem',
    fontWeight: 400,
    letterSpacing: '-.02em',
    lineHeight: '1.30357em',
    // marginLeft: '-.02em',
  },
  h3: {
    // color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: 'Arial, sans-serif',
    fontSize: '4.8rem',
    fontWeight: 400,
    lineHeight: '1.13333em',
    // marginLeft: '-.02em',
  },
  h4: {
    // color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: 'Arial, sans-serif',
    fontSize: '3.6rem',
    fontWeight: 400,
    lineHeight: '1.20588em',
  },
  h5: {
    // color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Arial, sans-serif',
    fontSize: '2.4rem',
    fontWeight: 400,
    lineHeight: '1.35417em',
  },
  h6: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Arial, sans-serif',
    fontSize: '2.1rem',
    fontWeight: 400,
    lineHeight: '1.16667em',
  },
  subtitle1: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.8rem',
    fontWeight: 400,
    lineHeight: '1.5em',
  },
  body1: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: '1.71429em',
  },
  body2: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.4rem',
    fontWeight: 400,
    lineHeight: '1.46429em',
  },
  caption: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.2rem',
    fontWeight: 400,
    lineHeight: '1.375em',
  },
  button: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.2rem',
    fontWeight: 300,
    textTransform: 'none',
  },
}
