export const DISMISS_ERROR = 'DISMISS_ERROR'

/**
 * Multiple Video Actions
 */
export const FV_VIDEOS_FETCH_START = 'FV_VIDEOS_FETCH_START'
export const FV_VIDEOS_FETCH_SUCCESS = 'FV_VIDEOS_FETCH_SUCCESS'
export const FV_VIDEOS_FETCH_ERROR = 'FV_VIDEOS_FETCH_ERROR'

export const FV_VIDEOS_UPDATE_START = 'FV_VIDEOS_UPDATE_START'
export const FV_VIDEOS_UPDATE_SUCCESS = 'FV_VIDEOS_UPDATE_SUCCESS'
export const FV_VIDEOS_UPDATE_ERROR = 'FV_VIDEOS_UPDATE_ERROR'

export const FV_VIDEOS_CREATE_START = 'FV_VIDEOS_CREATE_START'
export const FV_VIDEOS_CREATE_SUCCESS = 'FV_VIDEOS_CREATE_SUCCESS'
export const FV_VIDEOS_CREATE_ERROR = 'FV_VIDEOS_CREATE_ERROR'

export const FV_VIDEOS_DELETE_START = 'FV_VIDEOS_DELETE_START'
export const FV_VIDEOS_DELETE_SUCCESS = 'FV_VIDEOS_DELETE_SUCCESS'
export const FV_VIDEOS_DELETE_ERROR = 'FV_VIDEOS_DELETE_ERROR'

/**
 * Single Video Actions
 */
export const FV_VIDEO_FETCH_START = 'FV_VIDEO_FETCH_START'
export const FV_VIDEO_FETCH_SUCCESS = 'FV_VIDEO_FETCH_SUCCESS'
export const FV_VIDEO_FETCH_ERROR = 'FV_VIDEO_FETCH_ERROR'

export const FV_VIDEOS_SHARED_FETCH_START = 'FV_VIDEOS_SHARED_FETCH_START'
export const FV_VIDEOS_SHARED_FETCH_SUCCESS = 'FV_VIDEOS_SHARED_FETCH_SUCCESS'
export const FV_VIDEOS_SHARED_FETCH_ERROR = 'FV_VIDEOS_SHARED_FETCH_ERROR'

export const FV_VIDEO_UPLOAD_START = 'FV_VIDEO_UPLOAD_START'
export const FV_VIDEO_UPLOAD_SUCCESS = 'FV_VIDEO_UPLOAD_SUCCESS'
export const FV_VIDEO_UPLOAD_ERROR = 'FV_VIDEO_UPLOAD_ERROR'

export const FV_VIDEO_UPDATE_START = 'FV_VIDEO_UPDATE_START'
export const FV_VIDEO_UPDATE_SUCCESS = 'FV_VIDEO_UPDATE_SUCCESS'
export const FV_VIDEO_UPDATE_ERROR = 'FV_VIDEO_UPDATE_ERROR'

export const FV_VIDEO_CREATE_START = 'FV_VIDEO_CREATE_START'
export const FV_VIDEO_CREATE_SUCCESS = 'FV_VIDEO_CREATE_SUCCESS'
export const FV_VIDEO_CREATE_ERROR = 'FV_VIDEO_CREATE_ERROR'

export const FV_VIDEO_DELETE_START = 'FV_VIDEO_DELETE_START'
export const FV_VIDEO_DELETE_SUCCESS = 'FV_VIDEO_DELETE_SUCCESS'
export const FV_VIDEO_DELETE_ERROR = 'FV_VIDEO_DELETE_ERROR'

/*
import {
    DISMISS_ERROR,
    FV_VIDEOS_FETCH_START,
    FV_VIDEOS_FETCH_SUCCESS,
    FV_VIDEOS_FETCH_ERROR,
    FV_VIDEOS_UPDATE_START,
    FV_VIDEOS_UPDATE_SUCCESS,
    FV_VIDEOS_UPDATE_ERROR,
    FV_VIDEOS_CREATE_START,
    FV_VIDEOS_CREATE_SUCCESS,
    FV_VIDEOS_CREATE_ERROR,
    FV_VIDEOS_DELETE_START,
    FV_VIDEOS_DELETE_SUCCESS,
    FV_VIDEOS_DELETE_ERROR,
    FV_VIDEO_FETCH_START,
    FV_VIDEO_FETCH_SUCCESS,
    FV_VIDEO_FETCH_ERROR,
    FV_VIDEOS_SHARED_FETCH_START,
    FV_VIDEOS_SHARED_FETCH_SUCCESS,
    FV_VIDEOS_SHARED_FETCH_ERROR,
    FV_VIDEO_UPLOAD_START,
    FV_VIDEO_UPLOAD_SUCCESS,
    FV_VIDEO_UPLOAD_ERROR,
    FV_VIDEO_UPDATE_START,
    FV_VIDEO_UPDATE_SUCCESS,
    FV_VIDEO_UPDATE_ERROR,
    FV_VIDEO_CREATE_START,
    FV_VIDEO_CREATE_SUCCESS,
    FV_VIDEO_CREATE_ERROR,
    FV_VIDEO_DELETE_START,
    FV_VIDEO_DELETE_SUCCESS,
    FV_VIDEO_DELETE_ERROR,
} from './actionTypes'
*/
