import DirectoryOperations from 'operations/DirectoryOperations'
import { fetch, update } from 'reducers/rest'

import { FV_FETCH_PORTALS_FETCH_ERROR, FV_FETCH_PORTALS_FETCH_SUCCESS, FV_FETCH_PORTALS_START } from './actionTypes'

import { ENRICHER_HEADER } from 'common/Constants'

export const updatePortal = update('FV_PORTAL', 'FVPortal', {
  headers: { [ENRICHER_HEADER]: 'ancestry,portal' },
})
export const fetchPortal = fetch('FV_PORTAL', 'FVPortal', {
  headers: { [ENRICHER_HEADER]: 'ancestry,portal' },
})

export const fetchPortals = function fetchPortals(params) {
  return (dispatch) => {
    dispatch({ type: FV_FETCH_PORTALS_START })
    let queryParams = {}

    const urlQueryParams = new URLSearchParams(window.location.search)

    if (params.mySites || urlQueryParams.get('mySites')) {
      queryParams = {
        mySites: 'true',
      }
    }

    if (params?.region) {
      queryParams = {
        region: params?.region,
        ...queryParams
      }
    }

    return DirectoryOperations.getDocumentsViaCustomAPI('/site/', queryParams)
      .then((response) => {
        dispatch({ type: FV_FETCH_PORTALS_FETCH_SUCCESS, documents: response })
      })
      .catch((error) => {
        dispatch({ type: FV_FETCH_PORTALS_FETCH_ERROR, error: error })
      })
  }
}
