import { execute, fetch, query } from 'reducers/rest'
import { ENRICHER_HEADER } from 'common/Constants'

export const fetchResource = fetch('FV_RESOURCE', 'FVPicture,FVAudio,FVVideo', {
  headers: { [ENRICHER_HEADER]: 'ancestry,media,permissions' },
})
export const fetchResources = query('FV_RESOURCES', 'FVPicture,FVAudio,FVVideo', {
  headers: { [ENRICHER_HEADER]: 'ancestry,media,permissions' },
})

// Document.FollowLifecycleTransition expects a param that specifies the type of transition to take place e.g. { value: 'Publish' }
export const publishResource = execute('FV_RESOURCE_PUBLISH', 'Document.FollowLifecycleTransition', {
  headers: { [ENRICHER_HEADER]: 'ancestry,media,permissions' },
})
