export const DISMISS_ERROR = 'DISMISS_ERROR'

/**
 * Multiple Picture Actions
 */
export const FV_PICTURES_FETCH_START = 'FV_PICTURES_FETCH_START'
export const FV_PICTURES_FETCH_SUCCESS = 'FV_PICTURES_FETCH_SUCCESS'
export const FV_PICTURES_FETCH_ERROR = 'FV_PICTURES_FETCH_ERROR'

export const FV_PICTURES_UPDATE_START = 'FV_PICTURES_UPDATE_START'
export const FV_PICTURES_UPDATE_SUCCESS = 'FV_PICTURES_UPDATE_SUCCESS'
export const FV_PICTURES_UPDATE_ERROR = 'FV_PICTURES_UPDATE_ERROR'

export const FV_PICTURES_CREATE_START = 'FV_PICTURES_CREATE_START'
export const FV_PICTURES_CREATE_SUCCESS = 'FV_PICTURES_CREATE_SUCCESS'
export const FV_PICTURES_CREATE_ERROR = 'FV_PICTURES_CREATE_ERROR'

export const FV_PICTURES_DELETE_START = 'FV_PICTURES_DELETE_START'
export const FV_PICTURES_DELETE_SUCCESS = 'FV_PICTURES_DELETE_SUCCESS'
export const FV_PICTURES_DELETE_ERROR = 'FV_PICTURES_DELETE_ERROR'

/**
 * Single Picture Actions
 */
export const FV_PICTURE_FETCH_START = 'FV_PICTURE_FETCH_START'
export const FV_PICTURE_FETCH_SUCCESS = 'FV_PICTURE_FETCH_SUCCESS'
export const FV_PICTURE_FETCH_ERROR = 'FV_PICTURE_FETCH_ERROR'

export const FV_PICTURES_SHARED_FETCH_START = 'FV_PICTURES_SHARED_FETCH_START'
export const FV_PICTURES_SHARED_FETCH_SUCCESS = 'FV_PICTURES_SHARED_FETCH_SUCCESS'
export const FV_PICTURES_SHARED_FETCH_ERROR = 'FV_PICTURES_SHARED_FETCH_ERROR'

export const FV_PICTURE_UPLOAD_START = 'FV_PICTURE_UPLOAD_START'
export const FV_PICTURE_UPLOAD_SUCCESS = 'FV_PICTURE_UPLOAD_SUCCESS'
export const FV_PICTURE_UPLOAD_ERROR = 'FV_PICTURE_UPLOAD_ERROR'

export const FV_PICTURE_UPDATE_START = 'FV_PICTURE_UPDATE_START'
export const FV_PICTURE_UPDATE_SUCCESS = 'FV_PICTURE_UPDATE_SUCCESS'
export const FV_PICTURE_UPDATE_ERROR = 'FV_PICTURE_UPDATE_ERROR'

export const FV_PICTURE_CREATE_START = 'FV_PICTURE_CREATE_START'
export const FV_PICTURE_CREATE_SUCCESS = 'FV_PICTURE_CREATE_SUCCESS'
export const FV_PICTURE_CREATE_ERROR = 'FV_PICTURE_CREATE_ERROR'

export const FV_PICTURE_DELETE_START = 'FV_PICTURE_DELETE_START'
export const FV_PICTURE_DELETE_SUCCESS = 'FV_PICTURE_DELETE_SUCCESS'
export const FV_PICTURE_DELETE_ERROR = 'FV_PICTURE_DELETE_ERROR'

/*
import {
    DISMISS_ERROR,
    FV_PICTURES_FETCH_START,
    FV_PICTURES_FETCH_SUCCESS,
    FV_PICTURES_FETCH_ERROR,
    FV_PICTURES_UPDATE_START,
    FV_PICTURES_UPDATE_SUCCESS,
    FV_PICTURES_UPDATE_ERROR,
    FV_PICTURES_CREATE_START,
    FV_PICTURES_CREATE_SUCCESS,
    FV_PICTURES_CREATE_ERROR,
    FV_PICTURES_DELETE_START,
    FV_PICTURES_DELETE_SUCCESS,
    FV_PICTURES_DELETE_ERROR,
    FV_PICTURE_FETCH_START,
    FV_PICTURE_FETCH_SUCCESS,
    FV_PICTURE_FETCH_ERROR,
    FV_PICTURES_SHARED_FETCH_START,
    FV_PICTURES_SHARED_FETCH_SUCCESS,
    FV_PICTURES_SHARED_FETCH_ERROR,
    FV_PICTURE_UPLOAD_START,
    FV_PICTURE_UPLOAD_SUCCESS,
    FV_PICTURE_UPLOAD_ERROR,
    FV_PICTURE_UPDATE_START,
    FV_PICTURE_UPDATE_SUCCESS,
    FV_PICTURE_UPDATE_ERROR,
    FV_PICTURE_CREATE_START,
    FV_PICTURE_CREATE_SUCCESS,
    FV_PICTURE_CREATE_ERROR,
    FV_PICTURE_DELETE_START,
    FV_PICTURE_DELETE_SUCCESS,
    FV_PICTURE_DELETE_ERROR,
} from './actionTypes'
*/
