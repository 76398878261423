export const REPORT_DOCUMENTS_FETCH_START = 'REPORT_DOCUMENTS_FETCH_START'
export const REPORT_DOCUMENTS_FETCH_SUCCESS = 'REPORT_DOCUMENTS_FETCH_SUCCESS'
export const REPORT_DOCUMENTS_FETCH_ERROR = 'REPORT_DOCUMENTS_FETCH_ERROR'

export const REPORT_WORDS_ALL_FETCH_START = 'REPORT_WORDS_ALL_FETCH_START'
export const REPORT_WORDS_ALL_FETCH_SUCCESS = 'REPORT_WORDS_ALL_FETCH_SUCCESS'
export const REPORT_WORDS_ALL_FETCH_ERROR = 'REPORT_WORDS_ALL_FETCH_ERROR'

export const REPORT_PHRASES_ALL_FETCH_START = 'REPORT_PHRASES_ALL_FETCH_START'
export const REPORT_PHRASES_ALL_FETCH_SUCCESS = 'REPORT_PHRASES_ALL_FETCH_SUCCESS'
export const REPORT_PHRASES_ALL_FETCH_ERROR = 'REPORT_PHRASES_ALL_FETCH_ERROR'

export const REPORT_SONGS_ALL_FETCH_START = 'REPORT_SONGS_ALL_FETCH_START'
export const REPORT_SONGS_ALL_FETCH_SUCCESS = 'REPORT_SONGS_ALL_FETCH_SUCCESS'
export const REPORT_SONGS_ALL_FETCH_ERROR = 'REPORT_SONGS_ALL_FETCH_ERROR'

export const REPORT_STORIES_ALL_FETCH_START = 'REPORT_STORIES_ALL_FETCH_START'
export const REPORT_STORIES_ALL_FETCH_SUCCESS = 'REPORT_STORIES_ALL_FETCH_SUCCESS'
export const REPORT_STORIES_ALL_FETCH_ERROR = 'REPORT_STORIES_ALL_FETCH_ERROR'

/*
import {
    REPORT_DOCUMENTS_FETCH_START,
    REPORT_DOCUMENTS_FETCH_SUCCESS,
    REPORT_DOCUMENTS_FETCH_ERROR,
    REPORT_WORDS_ALL_FETCH_START,
    REPORT_WORDS_ALL_FETCH_SUCCESS,
    REPORT_WORDS_ALL_FETCH_ERROR,
    REPORT_PHRASES_ALL_FETCH_START,
    REPORT_PHRASES_ALL_FETCH_SUCCESS,
    REPORT_PHRASES_ALL_FETCH_ERROR,
    REPORT_SONGS_ALL_FETCH_START,
    REPORT_SONGS_ALL_FETCH_SUCCESS,
    REPORT_SONGS_ALL_FETCH_ERROR,
    REPORT_STORIES_ALL_FETCH_START,
    REPORT_STORIES_ALL_FETCH_SUCCESS,
    REPORT_STORIES_ALL_FETCH_ERROR,
} from './actionTypes'
*/
