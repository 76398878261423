import { execute, fetch, query, update } from 'reducers/rest'
import DocumentOperations from 'operations/DocumentOperations'
// console.log('! fvDialect:', { RESTActions, DocumentOperations }) // eslint-disable-line
import { FV_DIALECT_FETCH_START, FV_DIALECT_FETCH_SUCCESS, FV_DIALECT_FETCH_ERROR } from './actionTypes'

import { ENRICHER_HEADER } from 'common/Constants'

export const fetchDialect = (pathOrId) => {
  return (dispatch) => {
    dispatch({ type: FV_DIALECT_FETCH_START })

    return DocumentOperations.getDocument(pathOrId, 'FVDialect', {
      headers: { [ENRICHER_HEADER]: 'ancestry,dialect,permissions,acls' },
    })
      .then((response) => {
        dispatch({ type: FV_DIALECT_FETCH_SUCCESS, document: response })
      })
      .catch((error) => {
        dispatch({ type: FV_DIALECT_FETCH_ERROR, error: error })
      })
  }
}

export const updateDialect2 = update('FV_DIALECT2', 'FVDialect', {
  headers: { [ENRICHER_HEADER]: 'ancestry,dialect,permissions,acls' },
})

export const fetchDialect2 = fetch('FV_DIALECT2', 'FVDialect', {
  headers: { [ENRICHER_HEADER]: 'ancestry,dialect,permissions,acls' },
})

export const fetchDialects = query('FV_DIALECTS', 'FVDialect', {
  headers: { [ENRICHER_HEADER]: 'ancestry,dialect' },
})

export const fetchDialectList = execute('FV_DIALECT_LIST', 'Document.ListDialects', {})

