export const DISMISS_ERROR = 'DISMISS_ERROR'

/**
 * Single Document Actions
 */
export const DOCUMENT_FETCH_START = 'DOCUMENT_FETCH_START'
export const DOCUMENT_FETCH_SUCCESS = 'DOCUMENT_FETCH_SUCCESS'
export const DOCUMENT_FETCH_ERROR = 'DOCUMENT_FETCH_ERROR'

export const DOCUMENT_PUBLISH_START = 'DOCUMENT_PUBLISH_START'
export const DOCUMENT_PUBLISH_SUCCESS = 'DOCUMENT_PUBLISH_SUCCESS'
export const DOCUMENT_PUBLISH_ERROR = 'DOCUMENT_PUBLISH_ERROR'

/*
import {
    DISMISS_ERROR,
    DOCUMENT_FETCH_START,
    DOCUMENT_FETCH_SUCCESS,
    DOCUMENT_FETCH_ERROR,
    DOCUMENT_PUBLISH_START,
    DOCUMENT_PUBLISH_SUCCESS,
    DOCUMENT_PUBLISH_ERROR,
} from './actionTypes'
*/
