export const SET_LOCALE = 'SET_LOCALE'
export const SET_IMMERSION_MODE = 'SET_IMMERSION_MODE'
export const SET_WORKSPACE = 'SET_WORKSPACE'
export const GET_WORKSPACE_LABELS = 'GET_WORKSPACE_LABELS'
export const FV_LABELS_FETCH_START = 'FV_LABELS_FETCH_START'
export const FV_LABELS_FETCH_SUCCESS = 'FV_LABELS_FETCH_SUCCESS'
export const FV_LABELS_FETCH_ERROR = 'FV_LABELS_FETCH_ERROR'
export const SET_HELP_MODE = 'SET_HELP_MODE'
export const SET_EDITING_LABEL = 'SET_EDITING_LABEL'
export const ADD_NEW_LABEL = 'ADD_NEW_LABEL'
