import ky from 'ky'
import { Component } from 'react'

export class UrlForwardingService extends Component {

  constructor(props, context) {
    super(props, context)
    this.verifyUrl(props)
  }

  async verifyUrl(props){
    const dialectShortUrl = props?.routeParams?.dialectUrl
    
    // If version2 flag is enabled for a site, take it to its v2 site.
    // Else if version2 flag is not enabled, or the site does not exist
    // show the 404 page

    try {
      const resJson = await ky.get('/nuxeo/api/v1/site/' + dialectShortUrl).json()
      if (resJson?.features?.includes("version2")) {
        window.location.href = this.getVersion2Url(resJson?.sitename)
      } else {
        this.redirectTo404();
      }
    } catch (error) {
      if (error?.response?.status == 404) {
        this.redirectTo404();
      }
    }
  }

  getVersion2Url(sitename) {
    const currentUrl = window.location.href
    if (currentUrl.includes('dev.firstvoices.com')) {
      return `https://v2.dev.firstvoices.com/${sitename}`
    } else if (currentUrl.includes('preprod.firstvoices.com')) {
      return `https://v2.preprod.firstvoices.com/${sitename}`
    } else if (currentUrl.includes('localhost:3001')) {
      return `http://localhost:3002/${sitename}`
    } else {
      return `https://v2.firstvoices.com/${sitename}`
    }
  }

  redirectTo404() {
    window.location.href= '/404-page-not-found?error=missing_short_url'
  }

  render() {
    return null
  }

}

export default UrlForwardingService
